<template>
    <div class="indroduction-container">
        <div class="my-card my-font-md introduction" v-html="introduction" />
    </div>
</template>

<script>
import { getPage } from "@/api/common";
export default {
    components: {},
    data() {
        return {
            introduction: "",
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.getPage();
    },
    methods: {
        getPage() {
            getPage().then((res) => {
                if (res.code === 20000) {
                    this.introduction = res.data.netIntroduction;
                }
            });
        },
    },
};
</script>

<style scoped>
.indroduction-container {
}

.introduction {
    margin-bottom: 1rem;
    
}

/deep/ .introduction h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/deep/ .introduction h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/deep/ .introduction h3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/deep/ .introduction h4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/* /deep/ .introduction code {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
} */

/deep/ .introduction p {
    /* 段间距 */
    margin-bottom: 1rem;

    max-width: 1278px;
}

/deep/ .introduction table {
    border-top: 1px solid #dcdee2;
    border-left: 1px solid #dcdee2;
}

/deep/ .introduction table td,
table th {
    border-bottom: 1px solid #dcdee2;
    border-right: 1px solid #dcdee2;
    padding: 3px 5px;
}

/deep/ .introduction table th {
    border-bottom: 2px solid #dcdee2;
    border-right: 1px solid #dcdee2;
    text-align: center;
}

/deep/ .introduction blockquote {
    display: block;
    border-left: 8px solid #66b5f3;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
}

/deep/ .introduction code {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    background-color: #f1f1f1;
    border-radius: 3px;
    padding: 3px 5px;
    margin: 0 3px;
}

/deep/ .introduction pre code {
    display: block;
}

/* /deep/ .introduction ul,
ol {
    margin: 10px 0 10px 20px;
} */
</style>